.countdown-overlay {
  position: fixed;
  font-size: 50px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.countdown {
  font-size: 100px;
  color: white;
  animation: countdownFade 1s ease-in-out infinite;
}

@keyframes countdownFade {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.limited-width-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  max-width: 100%;
  overflow: hidden;
}

.marquee-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.marquee-text:hover {
  overflow: visible;
  animation: marquee 5s forwards;
}

@keyframes marquee {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(calc(-50% + 100px));
  }
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

.blinking-recording {
  animation: blink-animation 1s infinite;
  color: red;
}

.inactive-recording {
  color: grey;
}

.ready-recording {
  color: black;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.2));
}

.video-wrapper .resize-handle-button {
  display: none;
}

.video-wrapper:hover .resize-handle-button {
  display: inline-block;
  color: tomato;
}

#audioVisualizer {
  margin-bottom: 10px;
}

.video-recorder-card {
  width: 380px !important; /* 345px */
}

.video-recorder-card canvas {
  width: 100% !important;
  height: auto !important;
}

.scroll-container {
  overflow-x: auto;
  width: 100%;
}

.no-scroll {
  overflow: hidden;
}

.scroll-x {
  overflow-x: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
.file-viewer-container {
  overflow-x: auto;
  width: 100%;
}

*::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.no-visible-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-visible-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
