.ant-pro-layout .ant-pro-sider-actions {
  display: none;
}

.limited-width-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  max-width: 100%;
  overflow: hidden;
}

.marquee-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.marquee-text:hover {
  overflow: visible;
  animation: marquee 5s forwards;
}

@keyframes marquee {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(calc(-50% + 100px));
  }
}
